export const typeSchema = [
  {
    group: [
      {
        class: "row",
        group: [
          {
            class: "row",
            group: [
              {
                class: "row",
                group: [
                  {
                    class: "col-12 d-flex justify-content-between",
                    component: [
                      // Parameter label
                      {
                        name: "11",
                        label: "201",
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle mb-4 ",
                      },
                      {
                        label: "34", // Require
                        type: "HeadLabel",
                        class: "text-danger-500 fnt-regular fnt-caption",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",

                group: [
                  {
                    class: "col-12 mb-3",
                    component: [
                      // Parameter name
                      {
                        label: "116",
                        type: "InputText",
                        datavalue: "strParameterName",
                        alertMsg: "202",
                        hint: "Please input in English",
                        name: "inpParameterName",
                        validate: "letterEN",
                        maxlength: 100,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",

                group: [
                  {
                    class: "col-12 mb-3",
                    component: [
                      // Description
                      {
                        label: "117",
                        type: "InputLongText",
                        datavalue: "strParameterDescription",
                        alertMsg: "202",
                        hint: "Please input in English",
                        name: "inpParameterDescription",
                        validate: "",
                        require:"*",
                        maxlength: 300,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",

                group: [
                  {
                    class: "col-12 mb-3",
                    component: [
                      // Value
                      {
                        label: "118",
                        type: "inputDynamicSizeVue",
                        datavalue: "strValue",
                        alertMsg: "202",
                        hint: "Please input in English",
                        name: "strValue",
                        validate: "",
                        request: true,
                        maxlength: 400,
                      },
                    ],
                  },
                ]
              },
              {
                class: "row",

                group: [
                  {
                    class: "col-12 mb-3",
                    component: [
                      // Status
                      {
                        strName: "119",
                        type: "Dropdown",
                        strValueName: "intValuetype",
                        datavalue: "intValuetype",
                        listDataSelection: [
                          { id: 1, lang: "120" },
                          { id: 2, lang: "121" },
                          { id: 3, lang: "122" },
                          { id: 4, lang: "123" },
                        ],
                        strSelectedValue: "''",
                        relations: "''",
                        canChange: true,
                      },
                    ],
                  },
                ],
              },
              
              {
                class: "row",

                group: [
                  {
                    class:
                      "modal-footer border-0 justify-content-start pt-0 mt-6 ",
                    component: [
                      {
                        type: "Button",
                        name: "36", // OK
                        value: false,
                        state: "submit",
                        class: "btn-fix me-3",
                      },
                      {
                        type: "Button",
                        name: "35", // Cancel
                        value: false,
                        state: "cancel",
                        class: "btn-fix btn-cancel",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const request = [
  "strParameterName",
  "strParameterDescription",
  "strValue",
];
