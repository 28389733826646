<template>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('223')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <Header
    addHeader="d-none"
    icon="parameterOutline"
    :headerName="$t('11')"
    :breadcrumbname="
      action == 'Insert' ? $t('115') : '' || action == 'Update' ? $t('408') : ''
    "
    :btnName="$t('115')"
    class="mb-3"
    @onInput="openform($event)"
    :breadcrumb="blnOpenform == true ? '' : 'd-none'"
    :helperHeader="
      blnOpenform == true
        ? $t('200', { fieldname: $t('11').toLowerCase() })
        : ''
    "
    :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
    :rightHeader="blnOpenform == true ? 'd-none' : ''"
    :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
    @showFilter="openFilter($event)"
    id="header"
    ListParameterListClass="d-none"
    :Searchname="$t('114')"
    @onSearch="SearchParameterList($event)"
    @onClean="clean()"
    :blnOpenform="blnOpenform"
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter:
        activeFiltersSelected.length == 0 ||
        activeFiltersSelected.length ==
          this.listParameterFilter[0].list[1].data.length - 1
          ? false
          : true,
      disableFilter: false,
    }"
    ref="header"
  />
  <div class="popup-container" v-show="blnOpenFilter == true">
    <transition name="zoom">
      <Filter
        @appectFilter="filteredData($event)"
        v-show="blnOpenFilter == true"
        :listDataFilter="listParameterFilter"
        @Cancel="blnOpenFilter = !blnOpenFilter"
        :listActiveFiltersSelected="activeFiltersSelected"
        :blnOpenFilter="blnOpenFilter"
        :blnHaveDecision="false"
        ref="filter"
        btnFix="btn-group-fix"
        classFilter="filter-fix"
        :listdataTable="listFilterParameter"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnOpenFilter == true"
        @click="blnOpenFilter = false"
      ></div>
    </transition>
  </div>
  <Table
    :progressScore="progress"
    :nametable="'11'"
    v-show="ListParameter.dataFields != null"
    v-if="!blnOpenform"
    :ListDataTable="ListParameter"
    firstSort="-"
    secondSort="strParameterName"
    @edit="fetchGetParameterListbyId($event)"
    @ConfigDisplay="reloadConfigDisplay()"
    @onSaveConfigDisplay="onSaveConfigDisplay($event)"
    ref="table"
  />
  <div class="bg-white form-lg rounded-2 shadow-sm ms-9 mb-5">
    <Form
      v-if="blnOpenform"
      :typeSchema="typeSchema"
      :data="formdata"
      @onInput="openform($event)"
      @Insert="fetchInsertParameter($event)"
      @Update="fetchUpdateParameter($event)"
      :action="action"
      :request="request"
      :msgalertDataDuplicate="msgalertDataDuplicate"
      :inactive="formInactive"
      ref="form"
    />
  </div>
</template>

<script>
import Filter from "@/components/filter/filter.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Table from "@/components/table/table.vue";
import Form from "@/components/form/form.vue";
import URLAPI from "@/typescript/urlapi/url";
import APIParameter from "@/typescript/urlapi/adminsystem/APIParameter";
import { typeSchema, request } from "@/components/constants/schemaAddParameter";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
//import SortArray from "../typescript/sort/sorting";
import Enumerable from "linq";
import AltStatus from "@/components/alert/alertStatus.vue";
import AdsInventories from "@/typescript/inventory";
export default {
  components: {
    Header,
    Table,
    Form,
    Filter,
    AltStatus,
  },
  data() {
    return {
      blnloadError: false,
      strSearch: "",
      timerCountHover: 0,
      progress: 0,
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      hover: false, //hover alt
      typeSchema: typeSchema, //
      timerEnabled: false,
      request: request,
      formdata: {},
      formdatadummy: {},
      blnOpenform: false,
      ListParameter: {},
      ListDummy: {},
      parameterId: null,
      listSearch: {},
      updateAdsInventories: null,
      formInactive: [
        { name: "strParameterName", inactive: true },
        { name: "intValuetype", inactive: true },
        { name: "strParameterDescription", inactive: true },
      ], //Input disabled
      blnOpenFilter: false,
      blnopenConfigDisplay: false,
      listParameterFilter: [
        {
          list: [
            { name: "11" },
            {
              data: [
                { id: null, lang: "79" }, //all
                { id: 1, lang: "120" }, //text
                { id: 2, lang: "121" }, //number
                { id: 3, lang: "122" }, //boolean
                { id: 4, lang: "123" }, //currency
              ],
            },
            { state: "intValuetype" },
          ],
        },
      ],
      activeFiltersSelected: [],
      listFilterParameter: [],
    };
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    this.updateAdsInventories = setInterval(() => {
      // console.log(AdsInventories.blnCloseform);
      if (AdsInventories.blnCloseform == false) {
        this.openform(false);
        AdsInventories.blnCloseform = true;
      }
    }, 500);
  },
  beforeCreate() {},
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    // timerCount: {
    //   handler(value) {
    //     if (value < 10 && this.timerEnabled && this.progress < 75) {
    //       setTimeout(() => {
    //         this.timerCount++;

    //         this.progress += 5 * this.timerCount;
    //       }, 200);
    //     }
    //   },

    //   immediate: true, // This ensures the watcher is triggered upon creation
    // },
  },
  created() {
    this.processLoadingData();
  },
  methods: {
    // skeleton loading ข้อมูล
    async processLoadingData() {
      this.progress = 0;
      await this.fetchListParameterList().then(() => {
        this.processData().then(() => {
          setTimeout(() => {
            // console.log("100");
            if (this.blnloadError == false) {
              this.progress = 100;
            }
          }, 1000);
        });
      });
    },
    //จัดการข้อมูลแล้วไปแสดงผลในตาราง
    async processData() {
      try {
        setTimeout(() => {
          this.ListParameter.data = Enumerable.from(this.ListParameter.data)
            .orderByDescending((r) =>
              r.strParameterName.toString().toLowerCase()
            )
            .toArray();
          this.ListParameter.data = this.ListParameter.data.reverse();
        }, 1000);

        if (
          localStorage.getItem("SelectedListParameter") == null ||
          localStorage.getItem("SelectedListParameter") == "undefined"
        ) {
          localStorage.setItem(
            "SelectedListParameter",
            JSON.stringify(this.ListParameter.defaultField)
          );
        }

        setTimeout(() => {
          if (this.$refs.table != null) {
            this.$refs.table.onClean();
            this.$refs.table.calculatePage(
              this.ListParameter.total,
              this.ListParameter.data
            );
            // ค่า config ไม่เป็น undefined
            if (localStorage.getItem("SelectedListParameter") != "undefined") {
              this.$refs.table.setSelectedList(
                JSON.parse(localStorage.getItem("SelectedListParameter"))
              );
            } else {
              // ค่า config เป็น undefined ให้ใช้ config จาก defaultfield
              // และ set ค่า config นั้นไว้
              localStorage.setItem(
                "SelectedListParameter",
                JSON.stringify(this.ListParameter.defaultField)
              );
              this.$refs.table.setSelectedList(this.ListParameter.defaultField);
            }
          }
        }, 1000);
        this.$refs.filter.Clear();
        // console.log("processData");
        return true;
      } catch (error) {
        throw new Error(error);
      }
    },
    //เรียกบันทึกการตั้งค่า configdisplay
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem("SelectedListParameter"))
        );
      }
    },
    //ลบข้อมูลด้วย ID
    async fetchDeleteParameterList(ParameterListId) {
      await axios
        .delete(urlapi + APIParameter.deleteParameterList + ParameterListId)
        .then((res) => {
          if (res.data.candelete == true) {
            //delay 1 sec
            setTimeout(() => {
              this.processData();
            }, 1000);
            this.$refs.table.checkstateofdelete(true);
          } else {
            this.$refs.table.checkstateofdelete(false);
            setTimeout(() => {
              this.processData();
            }, 1000);
          }
        })
        .catch();
    },
    // อัพเดทข้อมูลพาราเมเตอร์
    async fetchUpdateParameter(event) {
      if (this.$refs.header != null) {
        this.$refs.header.onClear();
      }
      this.msgalertDataDuplicate = [];
      let strParameterName = event.strParameterName;
      let strParameterDescription = event.strParameterDescription;
      let strValue = event.strValue;
      let intValuetype = event.intValuetype;
      const formdata = {
        strParameterName,
        strParameterDescription,
        strValue,
        intValuetype,
      };
      // console.log(this.formdatadummy);
      let isEqual =
        JSON.stringify(this.formdatadummy) === JSON.stringify(event);
      // console.log(isEqual);
      if (isEqual == true) {
        this.blnOpenform = false;
        this.blnAltStatus = true;
        this.strSMSGsuccess = "27";
        this.progress = 0;
        setTimeout(() => {
          this.processLoadingData();
          this.blnAltStatus = false;
        }, 2000);
      }
      if (isEqual == false) {
        await axios
          .put(
            urlapi +
              APIParameter.updateParameter +
              this.parameterId +
              "?MemberID=" +
              // this.$UserInfo.intMemberID +
              AdsInventories.userinfo.intMemberID +
              "&Confirm=true",
            formdata
          )
          .then((res) => {
            if (res.data.edited == true) {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              setTimeout(() => {
                this.blnAltStatus = false;
              }, 2000);
              this.progress = 0;
              //delay 1 sec
              setTimeout(() => {
                //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
                this.processLoadingData();
              }, 1000);
            }
            if (res.data.edited == false) {
              if (res.data.parametername == true) {
                let Message = { name: "inpParameterName", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
              }

              this.$refs.form.detectalertDataDuplicate(
                this.msgalertDataDuplicate
              );
            }
            if (res.data.code === 500) {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
          })
          .catch(() => {
            this.blnAltStatusunsucess = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      }
    },
    // เพิ่มข้อมูลพาราเมเตอร์
    async fetchInsertParameter(data) {
      this.msgalertDataDuplicate = [];
      try {
        var res = await axios.post(urlapi + APIParameter.insertParameter, data);

        if (res.data.inserted == true) {
          this.blnOpenform = false;
          this.blnAltStatus = true;
          this.strSMSGsuccess = "26";

          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
          this.progress = 0;
          //delay 1 sec
          setTimeout(() => {
            //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
            this.processLoadingData();
          }, 1000);
        }
        if (res.data.inserted == false) {
          if (res.data.parametername == true) {
            let Message = { name: "inpParameterName", msg: "222" };
            this.msgalertDataDuplicate.push(Message);
          }

          this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;
        this.timerEnabled = true;

        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
      return true;
    },
    //เรียกข้อมูลของ ParameterList ทั้งหมด
    async fetchListParameterList() {
      this.blnloadError = true;
      await axios
        .get(URLAPI + APIParameter.getParameterList)
        .then((res) => {
          // console.log(res);
          this.ListDummy = res.data;
          this.ListParameter = res.data;
          this.blnloadError = false;
          this.listFilterParameter = this.ListParameter.data;
          // console.log("get list");
          return true;
          //เช็คข้อมูลวิทยาเขต ว่าลบได้หรือไม่
          //this.fetchCheckDeleteParameterList();
        })
        .catch((e) => {
          // this.blnAltLoadingData = true;
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            // this.blnAltLoadingData = false;
            this.blnAltStatusunsucess = false;
          }, 5000);
          console.log(e);
        });
    },
    findByTemplate(activeFilters, array) {
      return array.filter((item) => {
        return item[activeFilters.state] == activeFilters.id;
      });
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      this.ListParameter.data = { ...ListDummy };
      let filterArrayintValuetype = [];
      this.listFilterParameter = [];
      if (activeFilters.length != 0) {
        for (let field in this.ListParameter.data) {
          for (let active in activeFilters) {
            if (activeFilters[active]["state"] == "intValuetype") {
              if (
                this.ListParameter.data[field]["intValuetype"] ==
                activeFilters[active]["id"]
              ) {
                filterArrayintValuetype.push(this.ListParameter.data[field]);
              }
            }
          }
        }
        // console.log("result : " + filterArrayintValuetype);

        let filterArray = filterArrayintValuetype.slice();

        this.ListParameter.data = filterArray;

        if (this.$refs.table != null) {
          this.$refs.table.onClean();
          this.$refs.table.calculatePage(filterArray.length, filterArray);
        }

        this.blnOpenFilter = false;

        this.ListDummy.data = { ...ListDummy };
      } else {
        this.ListParameter.data = this.ListDummy.data;

        if (this.$refs.table != null) {
          this.$refs.table.onClean();
          this.$refs.table.calculatePage(
            this.ListDummy.data.length,
            this.ListDummy.data
          );
        }

        this.blnOpenFilter = false;

        this.ListDummy.data = { ...ListDummy };
      }
      this.listFilterParameter = filterArrayintValuetype;

      this.$refs.table.SearchListDataTable(this.strSearch);
    },
    //รับค่าจาก component filter
    filteredData(activeFilters) {
      //ถ้าไม่มีข้อมูลการ fillter จะไม่สามารถ fillter ได้
      // if (activeFilters.length == 0) {
      //   return;
      // }
      this.activeFiltersSelected = activeFilters;
      // console.log(this.listParameterFilter[0].list[1].data.length);

      if (
        activeFilters.length != 0 &&
        activeFilters.length < this.listParameterFilter[0].list[1].data.length
      ) {
        this.filter(activeFilters, this.ListDummy.data);
      } else {
        // this.ListParameter.data = this.ListDummy.data;
        this.filter(activeFilters, this.ListDummy.data);
        if (this.$refs.table != null) {
          this.$refs.table.onClean();
        }
      }
    },
    //บันทึกการตั้งค่า configdisplay
    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem(
        "SelectedListParameter",
        JSON.stringify(SelectedList)
      );
    },
    //เปิดฟอร์ม
    async openform(state) {
      try {
        this.activeFiltersSelected = [];
        //ปิดฟอร์ม
        if (state == false) {
          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
          // this.formdatadummy = {};
          // this.ListParameter = {};
          // this.ListDummy = {};
          await this.processLoadingData();
          if (this.$refs.header != null) {
            this.$refs.header.onClear();
          }
        }
        //เปิดฟอร์ม
        else if (state == true) {
          //กำหนด action insert
          this.action = "Insert";
          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
        }
        this.blnOpenform = state;
      } catch (error) {
        console.log(error);
      }
    },

    //ตรวจสอบว่าวิทยาเขตสามารถลบได้หรือไหม
    async fetchCheckDeleteParameterList() {
      this.ListParameterList.data.forEach((element, index) => {
        axios
          .get(URLAPI + APIParameter.checkdDeleteParameterList + element.strID)
          .then((res) => {
            // ถ้าลบได้ เก็บค่าตัวแปร blnParameterListStatus เป็น false
            if (res.data.candelete == true) {
              this.ListParameterList.data[index]["blnParameterListStatus"] = 0;
            }
            //ถ้าลบไม่ได้ เก็บค่าตัวแปร blnParameterListStatus เป็น true
            else {
              this.ListParameterList.data[index]["blnParameterListStatus"] = 1;
            }
          })
          .catch((e) => {
            this.blnAltLoadingData = true;
            this.timerCountHover = 0;
            this.timerEnabled = true;
            this.hover = false;
            setTimeout(() => {
              this.blnAltLoadingData = false;
            }, 5000);
            console.log(e);
          });
      });
    },

    //เรียกข้อมูล ParameterList จาก id
    async fetchGetParameterListbyId(ParameterListId) {
      try {
        var res = await axios.get(
          URLAPI + APIParameter.getParameterByID + ParameterListId
        );
        // console.log(res);
        if (res.data.message == "Success") {
          this.parameterId = ParameterListId;
          //กำหนดข้อมูลที่จะแสดงบน form
          this.action = "Update";
          // console.log(res.data);
          this.formdata = res.data.data;
          this.formdatadummy = { ...res.data.data };
          this.blnOpenform = true;
          //false คือปิด Input
          if (res.data.description == false) {
            var CloseDescription = {
              name: "strParameterDescription",
              inactive: true,
            };
            this.formInactive.push(CloseDescription);
          }
          if (res.data.value == false) {
            //ค่าพารามิเตอร์
            var CloseValue = {
              name: "strValue",
              inactive: true,
            };
            this.formInactive.push(CloseValue);
          } else if (res.data.value == true) {
            //ค่าพารามิเตอร์
            var OpenValue = {
              name: "strValue",
              inactive: false,
            };
            this.formInactive.push(OpenValue);
            if (res.data.data.intValuetype == 1) {
              // validate ตามประเภทที่เลือก
              var validateValueTxt = {
                name: "strValue",
                strvalidate: "",
              };
              this.formInactive.push(validateValueTxt);
            } else if (res.data.data.intValuetype == 2) {
              // validate ตามประเภทที่เลือก
              var validateValueNumber = {
                name: "strValue",
                strvalidate: "number",
              };
              this.formInactive.push(validateValueNumber);
            } else if (res.data.data.intValuetype == 3) {
              // validate ตามประเภทที่เลือก
              var validateValueBoolean = {
                name: "strValue",
                strvalidate: "letterENSymbol",
              };
              this.formInactive.push(validateValueBoolean);
            } else if (res.data.data.intValuetype == 4) {
              // validate ตามประเภทที่เลือก
              var validateValueCurrency = {
                name: "strValue",
                strvalidate: "currency",
              };
              this.formInactive.push(validateValueCurrency);
            }

            setTimeout(() => {
              this.$refs?.form?.$refs?.DynamicForm?.$refs[
                "strValue"
              ][0].Valid();
            }, 1000);
          }
        }
      } catch (error) {
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        console.log(error);
      }
    },
    //ตรวจสอบข้อมูลของชื่อ  ParameterList ก่อนการบันทึกข้อมูล
    async fetchDuplicateParameterList(data) {
      await axios
        .post(urlapi + APIParameter.duplicate, data)
        .then((res) => {
          if (
            res.data.blnParameterListNameEN == "false" &&
            res.data.blnParameterListInitialEN == "false" &&
            res.data.blnParameterListInitialTH == "false" &&
            res.data.blnParameterListNameTH == "false"
          ) {
            //ถ้าไม่มีการซ้ำกันของข้อมูล สามารถบันทึกข้อมูลใหม่ได้
            this.fetchInsertParameterList(data);
          }
        })
        .catch((e) => {
          this.blnAltLoadingData = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            this.blnAltLoadingData = false;
          }, 5000);
          console.log(e);
        });
    },
    //บันทึกข้อมูลของ ParameterList ไปยัง Database
    async fetchInsertParameterList(data) {
      await axios
        .post(URLAPI + APIParameter.insertParameterList, data)
        .then((res) => {
          this.formdata = res.data;
          this.blnOpenform = true;
          if (res.data == "true") {
            //delay 1 sec
            setTimeout(() => {
              //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
              this.fetchListParameterList();
            }, 1000);
          }
        })
        .catch((e) => {
          this.blnAltLoadingData = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            this.blnAltLoadingData = false;
          }, 5000);
          console.log(e);
        });
    },
    // เปิด Component filter ที่กดจาก heade
    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    // blnopenConfigDisplay(showDisplay) {
    //   this.blnopenConfigDisplay = showDisplay;

    // },
    // ค้นหาพาราเมเตอร์
    SearchParameterList(event) {
      this.strSearch = event;
      this.$refs.table.SearchListDataTable(event);
    },
    // clear การค้นหา
    clean() {
      this.strSearch = "";
      this.SearchParameterList("");
      //this.filteredData(this.activeFiltersSelected); //ถ้าเปิดใช้งาน ตัวเลขหน้าตารางจะเพี้ยน
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom.scss";
</style>
