const apiparameter = {
  insertParameter: "/v1/api/AdsParameter/InsertParams",
  updateParameter: "/v1/api/AdsParameter/UpdateParameter/",
  //deleteParameter: "/v1/api/AdsParameter/DeleteParamete/",
  getParameterList: "/v1/api/AdsParameter/ParameterList",
  getParameterByID: "/v1/api/AdsParameter/ParameterByID/",
  duplicate: "/v1/api/AdsParameter/DuplicateParamete",
};

export default apiparameter;
